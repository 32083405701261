import $ from 'jquery'
import { getCookie, setCookie } from './cookie'

const ANONYMOUS_FEATURE_FLAGS = ['DESIGN_PROFILE_QUIZ']
const BASE_URLS = [
  'http://yardzen.localhost',
  'https://yardzenstage.wpengine.com',
  'https://yardzendev.wpengine.com',
  'http://yardzendev.localhost',
  'https://yardzen.com',
]
const ALLOWED_HOMEPAGE_REDIRECT_PATHS = ['/']
const ALLOWED_BLOG_PATHS = ['/yzblog/']
const ALLOWED_CURB_APPEAL_PRICE_TEST_PATHS = ['/curb-appeal', '/packages']
const ALLOWED_FRONT_YARD_PRICE_TEST_PATHS = ['/front-yard', '/packages']

function handleSDKReady(client, featureFlags) {
  client.on(client.Event.SDK_READY, function () {
    const attributes = {
      first_time_visitor: isFirstTimeVisitor(),
    }

    const treatments = client.getTreatments(featureFlags, attributes)

    if (treatments.DESIGN_PROFILE_QUIZ === 'on') {
      $('#profileQuizButton1').attr(
        'href',
        'https://project.yardzen.com/design-consultation'
      )
      $('#profileQuizButton2').attr(
        'href',
        'https://project.yardzen.com/design-consultation'
      )
      $('.js-quiz-btn').attr(
        'href',
        'https://project.yardzen.com/design-consultation'
      )
    }

    // Remove the spinner once the sdk is ready for all treatments except a page redirect
    // To avoid a flash, we want the spinner to show until redirect occurs
    if (treatments.AB_BUILDREBATE_PROMO) {
      handleBuildRebateTest(treatments.AB_BUILDREBATE_PROMO == 'on')
    }

    const promoCodeIsApplied = getCookie('yzpdc') !== ''
  })

  client.on(client.Event.SDK_READY_TIMED_OUT, function () {
    console.error('Error loading Split.io SDK')
    removeLoadingSpinner()
  })
}

function setFirstTimeVisitorDate() {
  const firstTimeVisitDate = getCookie('yzftv')
  if (!firstTimeVisitDate) {
    setCookie('yzftv', new Date(), 365)
  }
}

function isFirstTimeVisitor() {
  const firstTimeVisitDate = new Date(getCookie('yzftv'))
  const today = new Date()
  if (
    firstTimeVisitDate &&
    firstTimeVisitDate.setHours(0, 0, 0, 0) == today.setHours(0, 0, 0, 0)
  ) {
    return true
  }
  return false
}

export function getSessionId() {
  let sessionId = getCookie('yzsa')
  if (!sessionId) {
    try {
      sessionId = window.analytics?.user()?.anonymousId()
    } catch {
      sessionId = 'key'
    }
  }

  return sessionId
}

function initSplitIoCode() {
  setFirstTimeVisitorDate()

  // remove loading spinner after 3 seconds just in case there are any issues with the split.io sdk
  setTimeout(function () {
    removeLoadingSpinner()
  }, 3000)

  // unhide price after 2 seconds just in case there are any issues with the split.io sdk
  setTimeout(function () {
    unhidePrice()
  }, 3000)

  const sessionId = getSessionId()
  const userId = sessionId
  var factory = splitio({
    core: {
      authorizationKey: php_vars.splitioKey,
      key: userId,
      trafficType: 'anonymous', // default traffic type
    },
  })

  // Init sdk on different trafic types using: https://help.split.io/hc/en-us/articles/360020448791-JavaScript-SDK#advanced-instantiate-multiple-sdk-clients
  // anonymous traffic type
  const anonymousUserClient = factory.client()
  handleSDKReady(anonymousUserClient, ANONYMOUS_FEATURE_FLAGS)
}

function removeLoadingSpinner() {
  $('.landing-page-content-wrapper').css('visibility', 'inherit')
  $('.landing-page-loading-spinner-wrapper').css('display', 'none')
}

function unhidePrice() {
  $('.js-price').css('visibility', 'inherit')
}

export default function initSplitTesting() {
  window.addEventListener('load', initSplitIoCode)
}
